let Misc = {
    mainMenuBurger: document.querySelector('.sidebar__nav-burger'),
    mainMenuClose: document.querySelector('.sidebar__nav-close'),
    //Removes the active class specified and adds it to the specified element
    toggleClass: function(activeClass, currentElement)
    {
        if(document.querySelector("." + activeClass) !== null)
        {
            document.querySelector("." + activeClass).classList.remove(activeClass);
        }

        currentElement.classList.add(activeClass);
    },
    //Adds a class to the body to show the main menu
    showMainMenu: function()
    {
        document.querySelector('html').classList.add('mobile-nav-active');
    },
    //Removes thea class from the body to show the main menu
    hideMainMenu: function()
    {
        document.querySelector('html').classList.remove('mobile-nav-active');
    },
    ajaxPost: function(url, data, success)
    {
        let params = typeof data === 'string' ? data : Object.keys(data).map(
            function(k)
            {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }
        ).join('&');

        let xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");

        xhr.open('POST', url);

        xhr.onreadystatechange = function()
        {
            if(xhr.readyState > 3 && xhr.status === 200)
            {
                success(xhr.responseText);
            }
        };

        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(params);

        return xhr;
    }
};



//Main menu burger/close click events
Misc.mainMenuBurger.addEventListener("click", function()
{
    Misc.showMainMenu();
});

Misc.mainMenuClose.addEventListener("click", function()
{
    Misc.hideMainMenu();
});

//Sidebar nav active class
let mainMenuItems = document.querySelectorAll('.sidebar__nav li a');

for(let i = 0; i < mainMenuItems.length; i ++)
{
    mainMenuItems[i].addEventListener("click", function()
    {
        Misc.hideMainMenu();
    });
}



//Sidebar nav active class
let mainNavItems = document.querySelectorAll(".sidebar__nav li");

for(let i = 0; i < mainNavItems.length; i ++)
{
    mainNavItems[i].addEventListener("click", function()
    {
        Misc.toggleClass('sidebar__nav__active', this);
    });
}



//Append class to body if IE 9 - 11
var iev = 0;
var ieold = (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
var trident = !!navigator.userAgent.match(/Trident\/7.0/);
var rv=navigator.userAgent.indexOf("rv:11.0");

if(ieold) iev = new Number(RegExp.$1);
if(navigator.appVersion.indexOf("MSIE 10") != -1) iev = 10;
if(trident && rv != -1) iev = 11;

if(iev == 9)
{
    document.body.className += 'ie ie' + iev;
}