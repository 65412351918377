var Form =
{
    requiredFieldClass: 'form__field',
    fieldFailedValidationClass: 'form__field--failed-validation',
    count: 0,
    validateFields: function(formID)
    {
        let sendForm = '',
            requiredFormFields = document.querySelectorAll('.form__field-required'),
            name = document.querySelector("input[name='full_name']"),
            phone = document.querySelector("input[name='phone_number']"),
            email = document.querySelector("input[name='email_address']"),
            message = document.querySelector("textarea[name='message']"),
            sum = document.querySelector("input[name='sum']"),
            how = document.querySelector("select[name='how_did_you_hear']");

        for(let i = 0; i < requiredFormFields.length; i ++)
        {
            if(requiredFormFields[i].value === '')
            {
                requiredFormFields[i].classList.add(this.fieldFailedValidationClass);
                sendForm += 'error';
            }
            else
            {
                requiredFormFields[i].classList.remove(this.fieldFailedValidationClass);
                sendForm += '';
            }
        }

        //Add fix for spam receiving
        if(name.value === 'Henrybrile')
        {
            sendForm += 'error';
        }

        if(sendForm === '')
        {
            document.getElementById('contact-form').submit();
        }

        //If all fields have passed validation, submit the data
        // if(sendForm === '')
        // {
        //     Misc.ajaxPost('/process-contact.php', 'full_name=' + name.value + '&phone_number=' + phone.value + '&email_address=' + email.value + '&message=' + message.value + '&sum=' + sum.value + '&how_did_you_hear=' + how.value, function(data)
        //     {
        //         if(data === 'success')
        //         {
        //             document.getElementById('contact-form-submit-success').style.display = 'block';
        //             document.getElementById('contact-form-submit-fail').style.display = 'none';
        //             document.getElementById('contact-form').style.display = 'none';
        //         }
        //         else
        //         {
        //             document.getElementById('contact-form-submit-success').style.display = 'none';
        //             document.getElementById('contact-form-submit-fail').style.display = 'block';
        //             document.getElementById('contact-form').style.display = 'block';
        //         }
        //     });
        // }
    }
};


let contactFormSubmit = document.getElementById('contact-form-submit');

if(contactFormSubmit)
{
    contactFormSubmit.addEventListener("click", function(event)
    {
        //Fix for IE as event.preventDefault is not supported
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);

        Form.validateFields('contact-form');
    });
}