var Animation = {
    inviewTriggerAnimation: function(elementIdentifier, classToAdd)
    {
        let animatedElements = document.querySelectorAll('.' + elementIdentifier);

        for(let i = 0; i < animatedElements.length; i ++)
        {
            inViewport(animatedElements[i], { offset: -100 }, function()
            {
                animatedElements[i].className += ' animated ' + classToAdd;
            });
        }
    }
};


//Various content animations
Animation.inviewTriggerAnimation('inview-trigger-animation-fade-in-up-lg', 'animated--fade-in-up animated--fade-in-up--lg');
Animation.inviewTriggerAnimation('inview-trigger-animation-fade-in-up-md', 'animated--fade-in-up animated--fade-in-up--md');
Animation.inviewTriggerAnimation('inview-trigger-animation-fade-in-up-sm', 'animated--fade-in-up animated--fade-in-up--sm');